import { loadView } from "@/helpers"

import { Business } from "@/services/business"
import { Services } from "@/services/services"
import { People } from "@/services/people"

export const AdvisorAssistChildren = [
  {
    path: "/1/hester-haynes",
    name: "Hester Haynes",
    component: loadView("Contact"),
    props: {
      id: 1,
      contact_id: 1,
      color: "#573164",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(1),
      pro: true,
      services: Services.GetServicesByBusinessId(1),
      people: People.GetPeopleByContactId(1)
    },
    meta: {
      id: 1,
      type: "Contacts",
      title: "Hester Haynes",
    }
  },
  {
    path: "/1/heinrich-koopman",
    name: "Heinrich Koopman",
    component: loadView("Contact"),
    props: {
      id: 1,
      contact_id: 2,
      color: "#573164",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(1),
      pro: true,
      services: Services.GetServicesByBusinessId(1),
      people: People.GetPeopleByContactId(2)
    },
    meta: {
      id: 2,
      type: "Contacts",
      title: "Heinrich Koopman",
    }
  },
  {
    path: "/1/aadila-essop",
    name: "Aadila Essop",
    component: loadView("Contact"),
    props: {
      id: 1,
      contact_id: 19,
      color: "#573164",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(1),
      pro: true,
      services: Services.GetServicesByBusinessId(1),
      people: People.GetPeopleByContactId(19)
    },
    meta: {
      id: 19,
      type: "Contacts",
      title: "Aadila Essop",
    }
  },
  {
    path: "/1/shrivar-naidoo",
    name: "Shrivar Naidoo",
    component: loadView("Contact"),
    props: {
      id: 1,
      contact_id: 28,
      color: "#573164",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(1),
      pro: true,
      services: Services.GetServicesByBusinessId(1),
      people: People.GetPeopleByContactId(28)
    },
    meta: {
      id: 28,
      type: "Contacts",
      title: "Shrivar Naidoo",
    }
  }
]
export const InfundChildren = [
  {
    path: "/2/paul-von-stein",
    name: "Paul von Stein",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 6,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(6)
    },
    meta: {
      id: 6,
      type: "Contacts",
      title: "Paul von Stein",
    }
  },
  {
    path: "/2/siya-maki",
    name: "Siya Maki",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 7,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(7)
    },
    meta: {
      id: 7,
      type: "Contacts",
      title: "Siya Maki",
    }
  },
  {
    path: "/2/nico-burger",
    name: "Nico Burger",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 8,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(8)
    },
    meta: {
      id: 8,
      type: "Contacts",
      title: "Nico Burger",
    }
  },
  {
    path: "/2/fred-liedtke",
    name: "Fred Liedtke",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 9,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(9)
    },
    meta: {
      id: 9,
      type: "Contacts",
      title: "Fred Liedtke",
    }
  },
  {
    path: "/2/ainsley-moos",
    name: "Ainsley Moos",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 10,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(10)
    },
    meta: {
      id: 10,
      type: "Contacts",
      title: "Ainsley Moos",
    }
  },
  {
    path: "/2/monrico-arries",
    name: "Monrico Arries",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 11,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(11)
    },
    meta: {
      id: 11,
      type: "Contacts",
      title: "Monrico Arries",
    }
  },
  {
    path: "/2/simphiwe-zulu",
    name: "Simphiwe Zulu",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 12,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(12)
    },
    meta: {
      id: 12,
      type: "Contacts",
      title: "Simphiwe Zulu",
    }
  },
  {
    path: "/2/robert-camagu",
    name: "Robert Camagu",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 13,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(13)
    },
    meta: {
      id: 13,
      type: "Contacts",
      title: "Robert Camagu",
    }
  },
  {
    path: "/2/wesley-maguire",
    name: "Wesley Maguire",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 14,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(14)
    },
    meta: {
      id: 14,
      type: "Contacts",
      title: "Wesley Maguire",
    }
  },
  {
    path: "/2/hlonipa-matshamba",
    name: "Hlonipa Matshamba",
    component: loadView("Contact"),
    props: {
      id: 2,
      contact_id: 17,
      color: "#002549",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(2),
      pro: true,
      services: Services.GetServicesByBusinessId(2),
      people: People.GetPeopleByContactId(17)
    },
    meta: {
      id: 17,
      type: "Contacts",
      title: "Hlonipa Matshamba",
    }
  }
]
export const ChandlerAssetProtectionChildren = [
  {
    path: "/3/didier-debellaire",
    name: "Didier Debellaire",
    component: loadView("Contact"),
    props: {
      id: 3,
      contact_id: 3,
      color: "#203864",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(3),
      pro: true,
      services: Services.GetServicesByBusinessId(3),
      people: People.GetPeopleByContactId(3)
    },
    meta: {
      id: 3,
      type: "Contacts",
      title: "Didier Debellaire",
    }
  },
  {
    path: "/3/etienne-de-lassus",
    name: "Etienne de Lassus",
    component: loadView("Contact"),
    props: {
      id: 3,
      contact_id: 4,
      color: "#203864",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(3),
      pro: true,
      services: Services.GetServicesByBusinessId(3),
      people: People.GetPeopleByContactId(4)
    },
    meta: {
      id: 4,
      type: "Contacts",
      title: "Etienne de Lassus",
    }
  }
]

export const ChandlerBrokerInsuranceChildren = [
  {
    path: "/4/guillaume-colas",
    name: "Guillaume Colas",
    component: loadView("Contact"),
    props: {
      id: 4,
      contact_id: 5,
      color: "#203864",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(4),
      pro: true,
      services: Services.GetServicesByBusinessId(4),
      people: People.GetPeopleByContactId(5)
    },
    meta: {
      id: 5,
      type: "Contacts",
      title: "Guillaume Colas",
    }
  }
]

export const PortfoliumWealthChildren = []

export const YourcardChildren = []

export const ChandlerProfessionalsChildren = []

export const PrivateWealthManagementChildren = [
  {
    path: "/8/wilna-potgieter",
    name: "Wilna Potgieter",
    component: loadView("Contact"),
    props: {
      id: 8,
      contact_id: 15,
      color: "#19a3a9",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 4,
      brochure: false,
      data: Business.GetBusinessById(8),
      pro: true,
      services: Services.GetServicesByBusinessId(8),
      people: People.GetPeopleByContactId(15)
    },
    meta: {
      id: 15,
      type: "Contacts",
      title: "Wilna Potgieter",
    }
  }
]

export const MitonOptimalChildren = [
  {
    path: "/9/jolandi-slabbert",
    name: "Jolandi Slabbert",
    component: loadView("Contact"),
    props: {
      id: 9,
      contact_id: 16,
      color: "#054b7f",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: true,
      data: Business.GetBusinessById(9),
      pro: true,
      services: Services.GetServicesByBusinessId(9),
      people: People.GetPeopleByContactId(16)
    },
    meta: {
      id: 16,
      type: "Contacts",
      title: "Jolandi Slabbert",
    }
  },
  {
    path: "/9/sarah-cowen",
    name: "Sarah Cowen",
    component: loadView("Contact"),
    props: {
      id: 9,
      contact_id: 20,
      color: "#054b7f",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(9),
      pro: true,
      services: Services.GetServicesByBusinessId(9),
      people: People.GetPeopleByContactId(20)
    },
    meta: {
      id: 20,
      type: "Contacts",
      title: "Sarah Cowen",
    }
  },
  {
    path: "/9/george-dell",
    name: "George Dell",
    component: loadView("Contact"),
    props: {
      id: 9,
      contact_id: 29,
      color: "#054b7f",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(9),
      pro: true,
      services: Services.GetServicesByBusinessId(9),
      people: People.GetPeopleByContactId(29)
    },
    meta: {
      id: 29,
      type: "Contacts",
      title: "George Dell",
    }
  },
  {
    path: "/9/riaan-maartens",
    name: "Riaan Maartens",
    component: loadView("Contact"),
    props: {
      id: 9,
      contact_id: 30,
      color: "#054b7f",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(9),
      pro: true,
      services: Services.GetServicesByBusinessId(9),
      people: People.GetPeopleByContactId(30)
    },
    meta: {
      id: 30,
      type: "Contacts",
      title: "Riaan Maartens",
    }
  }
]

export const VrouebedieningChildren = []

export const FairbairnConsultChildren = []

export const GaelGroupChildren = [
  {
    path: "/11/liza-fourie",
    name: "Liza Fourie",
    component: loadView("Contact"),
    props: {
      id: 11,
      contact_id: 18,
      color: "#dfb753",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(11),
      pro: true,
      services: Services.GetServicesByBusinessId(11),
      people: People.GetPeopleByContactId(18)
    },
    meta: {
      id: 18,
      type: "Contacts",
      title: "Liza Fourie",
    }
  },
  {
    path: "/11/tony-christien",
    name: "Tony Christien",
    component: loadView("Contact"),
    props: {
      id: 11,
      contact_id: 21,
      color: "#dfb753",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(11),
      pro: true,
      services: Services.GetServicesByBusinessId(11),
      people: People.GetPeopleByContactId(21)
    },
    meta: {
      id: 21,
      type: "Contacts",
      title: "Tony Christien",
    }
  },
  {
    path: "/11/damian-newell",
    name: "Damian Newell",
    component: loadView("Contact"),
    props: {
      id: 11,
      contact_id: 22,
      color: "#dfb753",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(11),
      pro: true,
      services: Services.GetServicesByBusinessId(11),
      people: People.GetPeopleByContactId(22)
    },
    meta: {
      id: 22,
      type: "Contacts",
      title: "Damian Newell",
    }
  },
  {
    path: "/11/evelyn-pickard",
    name: "Evelyn Pickard",
    component: loadView("Contact"),
    props: {
      id: 11,
      contact_id: 23,
      color: "#dfb753",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(11),
      pro: true,
      services: Services.GetServicesByBusinessId(11),
      people: People.GetPeopleByContactId(23)
    },
    meta: {
      id: 23,
      type: "Contacts",
      title: "Evelyn Pickard",
    }
  },
  {
    path: "/11/michael-lambrecht",
    name: "Michael Lambrecht",
    component: loadView("Contact"),
    props: {
      id: 11,
      contact_id: 27,
      color: "#dfb753",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(11),
      pro: true,
      services: Services.GetServicesByBusinessId(11),
      people: People.GetPeopleByContactId(27)
    },
    meta: {
      id: 27,
      type: "Contacts",
      title: "Michael Lambrecht",
    }
  }
]

export const BficientChildren = [
  {
    path: "/13/aadila-essop",
    name: "Aadila Essop",
    component: loadView("Contact"),
    props: {
      id: 13,
      contact_id: 26,
      color: "#fecb32",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(13),
      pro: true,
      services: Services.GetServicesByBusinessId(13),
      people: People.GetPeopleByContactId(26)
    },
    meta: {
      id: 26,
      type: "Contacts",
      title: "Aadila Essop",
    }
  }
]

export const GaelGroupMaltaChildren = [
  {
    path: "/14/annemarie-borg",
    name: "Annemarie Borg",
    component: loadView("Contact"),
    props: {
      id: 14,
      contact_id: 24,
      color: "#dfb753",
      color_dark: "",
      color_bd: "#fff",
      banner_count: 1,
      brochure: false,
      data: Business.GetBusinessById(14),
      pro: true,
      services: Services.GetServicesByBusinessId(14),
      people: People.GetPeopleByContactId(24)
    },
    meta: {
      id: 24,
      type: "Contacts",
      title: "Annemarie Borg",
    }
  }
]
