/* eslint-disable no-console */

import { register, unregister } from 'register-service-worker'

console.log('process.env', process.env);

const getOptions = function () {
  return {
    registrationOptions: {
      scope: ""
    },
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      // register.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  }
} 

const urls = [
  "advisor-assist",
  "1/hester-haynes",
  "1/heinrich-koopman",
  "infund",
  "2/paul-von-stein",
  "2/siya-maki",
  "2/nico-burger",
  "2/fred-liedtke",
  "2/ainsley-moos",
  "2/monrico-arries",
  "2/simphiwe-zulu",
  "2/robert-camagu",
  "2/wesley-maguire",
  "2/hlonipa-matshamba",
  "chandler-asset-protection",
  "3/didier-debellaire",
  "3/etienne-de-lassus",
  "chandler-broker",
  "4/guillaume-colas",
  "portfolium-wealth",
  "yourcard",
  "chandler-professionals",
  "private-wealth-management",
  "8/wilna-potgieter",
  "mitonoptimal",
  "9/jolandi-slabbert",
  "vrouebediening-tygerberg-gesinskerk",
  "gael-group",
  "11/liza-fourie",
  "1/aadila-essop",
  "9/sarah-cowen",
  "11/tony-christien",
  "11/damian-newell",
  "11/evelyn-pickard",
  "bficient",
  "13/aadila-essop",
  "gael-group-malta",
  "14/annemarie-borg",
  "11/michael-lambrecht",
  "28/shrivar-naidoo",
  "fairbairn-consult",
  "9/george-dell",
  "9/riaan-maartens"

]

if (process.env.NODE_ENV === 'production') {
  
  navigator.serviceWorker.getRegistrations()
    .then(function(registrations) {
      console.log('registrations', registrations)
      for(let registration of registrations) {
        registration.unregister()
      } 
    })

  // set the current service worker
  for (const pathname of urls) {
    if (`/${pathname}` == location.pathname) {
      let options = getOptions();
      options.registrationOptions.scope = process.env.BASE_URL + pathname;
      console.log("Register", options.registrationOptions.scope);
      register(`${process.env.BASE_URL}service-worker.js`, options);
    }
  }
}
