import jsonData from '/public/data/businesses.json'
import { Locations } from './locations'
import { BusinessProfile } from "./businessProfile";

export const Business = {
  GetBusinessById: (id) => {
    if (!id || id == 0) return

    const business = jsonData.filter(x => x.id == id)[0]
    business.locations = Locations.GetLocationByBusinessId(id)
    business.profile = BusinessProfile.GetBusinessProfileByBusinessId(id);

    return business
  }
}
