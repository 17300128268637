const state = {
    navOpen: false
}

const getters = {
    navOpen: state => state.navOpen
}

const actions = {
    navOpen(context, payload) {
        context.commit('navOpen', payload)
    }
}

const mutations = {
    navOpen(state, { navOpen }) {
        state.navOpen = navOpen
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}