import Vue from 'vue'
import VueRouter from 'vue-router'

import { Business } from "@/services/business"
import { Services } from "@/services/services"

import { 
  AdvisorAssistChildren,
  InfundChildren,
  ChandlerAssetProtectionChildren,
  ChandlerBrokerInsuranceChildren,
  PortfoliumWealthChildren,
  YourcardChildren,
  ChandlerProfessionalsChildren,
  PrivateWealthManagementChildren,
  MitonOptimalChildren,
  VrouebedieningChildren,
  GaelGroupChildren,
  BficientChildren,
  GaelGroupMaltaChildren,
  FairbairnConsultChildren
} from "./children";

import { loadView } from "@/helpers"

Vue.use(VueRouter)

const routes = [
    {
      path: "/",
      name: "Home",
      component: loadView("Home"),
      meta: {
        title: "Directory"
      }
    },
    {
      path: "/admin",
      name: "Admin",
      component: loadView("Admin"),
      meta: {
        title: "Admin"
      }
    },
    {
      path: "/advisor-assist",
      name: "Advisor Assist",
      component: loadView("Business"),
      props: {
        id: 1,
        color: "#573164",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(1),
        pro: true,
        services: Services.GetServicesByBusinessId(1),
      },
      meta: {
        id: 1,
        type: "Businesses",
        title: "Advisor Assist"
      }
    },
    ...AdvisorAssistChildren,
    {
      path: "/infund",
      name: "Infund Solutions",
      component: loadView("Business"),
      props: {
        id: 2,
        color: "#203864",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 4,
        brochure: false,
        data: Business.GetBusinessById(2),
        pro: false,
        services: Services.GetServicesByBusinessId(2),
      },
      meta: {
        id: 2,
        type: "Businesses",
        title: "Infund Solutions",
      }
    },
    ...InfundChildren,
    {
      path: "/chandler-asset-protection",
      name: "Chandler Asset Protection Ltd",
      component: loadView("Business"),
      props: {
        id: 3,
        color: "#203864",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(3),
        pro: false,
        services: Services.GetServicesByBusinessId(3),
      },
      meta: {
        id: 3,
        type: "Businesses",
        title: "Chandler Asset Protection Ltd",
      }
    },
    ...ChandlerAssetProtectionChildren,
    {
      path: "/chandler-broker",
      name: "Chandler Broker Ltd",
      component: loadView("Business"),
      props: {
        id: 4,
        color: "#203864",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(4),
        pro: false,
        services: Services.GetServicesByBusinessId(4),
      },
      meta: {
        id: 4,
        type: "Businesses",
        title: "Chandler Broker Ltd",
      }
    },
    ...ChandlerBrokerInsuranceChildren,
    {
      path: "/portfolium-wealth",
      name: "Portfolium Wealth",
      component: loadView("Business"),
      props: {
        id: 5,
        color: "#e72e2c",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 4,
        brochure: false,
        data: Business.GetBusinessById(5),
        pro: false,
        services: Services.GetServicesByBusinessId(5),
      },
      meta: {
        id: 5,
        type: "Businesses",
        title: "Portfolium Wealth",
      }
    },
    ...PortfoliumWealthChildren,
    {
      path: "/yourcard",
      name: "YOURCard",
      component: loadView("Business"),
      props: {
        id: 6,
        color: "#3d1e60",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 4,
        brochure: false,
        data: Business.GetBusinessById(6),
        pro: false,
        services: Services.GetServicesByBusinessId(6),
      },
      meta: {
        id: 6,
        type: "Businesses",
        title: "YOURCard",
      }
    },
    ...YourcardChildren,
    {
      path: "/chandler-professionals",
      name: "Chandler Professionals Ltd",
      component: loadView("Business"),
      props: {
        id: 7,
        color: "#203864",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(7),
        pro: false,
        services: Services.GetServicesByBusinessId(7),
      },
      meta: {
        id: 7,
        type: "Businesses",
        title: "Chandler Professionals Ltd",
      }
    },
    ...ChandlerProfessionalsChildren,
    {
      path: "/private-wealth-management",
      name: "Private Wealth Management",
      component: loadView("Business"),
      props: {
        id: 8,
        color: "#19a3a9",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 4,
        brochure: false,
        data: Business.GetBusinessById(8),
        pro: false,
        services: Services.GetServicesByBusinessId(8),
      },
      meta: {
        id: 8,
        type: "Businesses",
        title: "Private Wealth Management",
      }
    },
    ...PrivateWealthManagementChildren,
    {
      path: "/mitonoptimal",
      name: "MitonOptimal",
      component: loadView("Business"),
      props: {
        id: 9,
        color: "#054b7f",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(9),
        pro: false,
        services: Services.GetServicesByBusinessId(9),
      },
      meta: {
        id: 9,
        type: "Businesses",
        title: "MitonOptimal",
      }
    },
    ...MitonOptimalChildren,
    {
      path: "/vrouebediening-tygerberg-gesinskerk",
      name: "Vrouebediening Tygerberg Gesinskerk",
      component: loadView("Business"),
      props: {
        id: 10,
        color: "#c28ab7",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(10),
        pro: false,
        services: Services.GetServicesByBusinessId(10),
      },
      meta: {
        id: 10,
        type: "Businesses",
        title: "Vrouebediening Tygerberg Gesinskerk",
      }
    },
    ...VrouebedieningChildren,

    {
      path: "/gael-group",
      name: "Gael Group",
      component: loadView("Business"),
      props: {
        id: 11,
        color: "#dfb753",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(11),
        pro: false,
        services: Services.GetServicesByBusinessId(11),
      },
      meta: {
        id: 11,
        type: "Businesses",
        title: "Gael Group",
      }
    },
    ...GaelGroupChildren,

    {
      path: "/bficient",
      name: "Bficient",
      component: loadView("Business"),
      props: {
        id: 13,
        color: "#fecb32",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(13),
        pro: true,
        services: Services.GetServicesByBusinessId(13),
      },
      meta: {
        id: 13,
        type: "Businesses",
        title: "Bficient",
      }
    },
    ...BficientChildren,

    {
      path: "/gael-group-malta",
      name: "Gael Group Malta",
      component: loadView("Business"),
      props: {
        id: 14,
        color: "#dfb753",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(14),
        pro: false,
        services: Services.GetServicesByBusinessId(14),
      },
      meta: {
        id: 14,
        type: "Businesses",
        title: "Gael Group Malta",
      }
    },
    ...GaelGroupMaltaChildren,

   {
      path: "/fairbairn-consult",
      name: "Fairbairn Consult",
      component: loadView("Business"),
      props: {
        id: 15,
        color: "#0f6078",
        color_dark: "",
        color_bd: "#fff",
        banner_count: 1,
        brochure: false,
        data: Business.GetBusinessById(15),
        pro: false,
        services: Services.GetServicesByBusinessId(15),
      },
      meta: {
        id: 15,
        type: "Businesses",
        title: "Fairbairn Consult",
      }
   },
    ...FairbairnConsultChildren,
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = "Digital Business Card";

router.beforeEach((to,from,next)=>{

  const e = document.querySelector('link[rel="manifest"]');
  e.remove()

  Vue.nextTick(()=>{
    document.title = to.meta.title || DEFAULT_TITLE;
    // dynamic manifest
    if (to.meta.title != 'Directory') {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.href = `/files/${to.meta.type}/${to.meta.id}/documents/manifest.json`;
      link.rel = 'manifest';
      head.appendChild(link);
    }
  })
  next();
})

router.afterEach((to, from) => {
  navigator.serviceWorker.ready.then(registration => { 
    registration.update(); 
  })
});

export default router
