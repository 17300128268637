<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  name: 'App',
  mounted() {
   
  }
}
</script>
<style lang="scss">
@import './scss/styles.scss';
</style>